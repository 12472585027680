
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from './_form.vue';
import TrainingCenterModule from '@/store/settings/trainingCenter';
import Form from '@/layouts/Form';
import TrainingCenterEntityModule from '@/store/settings/trainingCenter/entity';
import Loading from '@/components/Loading.vue';
import { Component } from '@/lib/decorator';

@Component({
  components: {
    Loading,
    TitleReturn,
    FormBlock,
  },
})
export default class TrainingCenterEntityAdd extends Form {
  isLoading = true;

  get titlePage(): string {
    return TrainingCenterModule.pageSettings.titleAdd;
  }

  returnBefore() {
    this.$router.push({ name: 'training_center' });
  }

  async mounted() {
    await TrainingCenterEntityModule.initAdd();
    this.isLoading = false;
  }
}
